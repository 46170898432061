import moment, { Moment } from 'moment';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';

import M3SurfaceContainer from '../../components/M3/M3SurfaceContainer';
import { M3OptionItem } from '../../components/Popover/BasicPopoverWithSearch';
import DateRangeSelectionPopover from '../../components/Filters/DateRangeSelectionPopover';
import FilterColumnPanel from '../../components/Filters/FilterColumnPanel';
import AutocompleteSelectionPopover, {
  AutocompleteFormState,
} from '../../components/Filters/AutocompleteSelectionPopover';
import { FormStateRet, useForm } from '../../components/BasicForm';
import FormPanel from '../../components/Panels/FormPanel';
import { useAutocompleteUserSearch } from '../../components/Filters/AutocompleteUserSearch';
import AutocompleteSearch, {
  AutocompleteSearchOptionItem,
} from '../../components/Filters/AutocompleteSearch';

import { ReactRenderElement } from '../../types/types';
import { UserKeystroke } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import {
  useAdminDivisions,
  UseAdminDivisionsProps,
} from '../../hooks/divisions';
import { useUserSearch } from '../../hooks/profile';
import { useSearchUrlHosts } from '../../hooks/go2-agent/activities';
import { useSearchGo2Versions } from '../../hooks/go2-agent/system-info';

export type KeystrokeFilterParams = {
  start_date: Moment;
  end_date: Moment;
  users: M3OptionItem[];
  divisions: M3OptionItem[];
  urlHosts: M3OptionItem[];
  go2Versions: M3OptionItem[];
};

type FiltersBoardProps = {
  filterParams: KeystrokeFilterParams;
  fieldLabelMap: { [K in keyof Partial<UserKeystroke>]: string };
  allFields: (keyof UserKeystroke)[];
  selectedFields: (keyof UserKeystroke)[];
  onFieldSelect?: (field: keyof UserKeystroke, remove?: boolean) => void;
  onFieldsChange?: (fields: (keyof UserKeystroke)[]) => void;
  updateFilterParams: (params: Partial<KeystrokeFilterParams>) => void;
};

const FiltersBoard = ({
  filterParams,
  fieldLabelMap,
  allFields,
  selectedFields,
  onFieldSelect,
  onFieldsChange,
  updateFilterParams,
}: FiltersBoardProps) => {
  // Users ---------------------------------------------------------------------
  const autocompleteUserSearch = useAutocompleteUserSearch();
  const [userSearchKeyCounter, setUserSearchKeyCounter] = useState(0);
  const handleOnUserSearchSelected = useCallback(
    (users: any) => {
      autocompleteUserSearch.handleOnUserSearchSelected(users);
      updateFilterParams({
        users: users as unknown as KeystrokeFilterParams['users'],
      });
    },
    // eslint-disable-next-line
    [updateFilterParams, autocompleteUserSearch.handleOnUserSearchSelected],
  );

  // Divisions -----------------------------------------------------------------
  const [divisionsSelected, setDivisionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [divisionKeyCounter, setDivisionKeyCounter] = useState(0);
  const getDivisionsOptionFormatter = useCallback(
    (div: any) =>
      ({
        id: div.id,
        label: div.name,
        props: div,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnDivisionSearchSelected = useCallback(
    (divisions: any) => {
      setDivisionsSelected(divisions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        divisions: divisions as unknown as KeystrokeFilterParams['divisions'],
      });
    },
    [setDivisionsSelected, updateFilterParams],
  );

  // HOST/Domain ---------------------------------------------------------------
  const [urlHostSelected, setUrlHostSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [urlHostKeyCounter, setUrlHostKeyCounter] = useState(0);
  const getUrlHostOptionFormatter = useCallback(
    (v: any) =>
      ({
        id: v.host,
        label: v.host,
        props: v,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnUrlHostSearchSelected = useCallback(
    (urlHosts: any) => {
      setUrlHostSelected(urlHosts as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        urlHosts: urlHosts as unknown as KeystrokeFilterParams['urlHosts'],
      });
    },
    [setUrlHostSelected, updateFilterParams],
  );

  // Go2 Versions ---------------------------------------------------------------
  const [go2VersionsSelected, setGo2VersionsSelected] = useState<
    AutocompleteSearchOptionItem[]
  >([]);
  const [go2VersionKeyCounter, setGo2VersionKeyCounter] = useState(0);
  const getGo2VersionsOptionFormatter = useCallback(
    (v: any) =>
      ({
        id: v.go2_version,
        label: v.go2_version,
        props: v,
      } as AutocompleteSearchOptionItem),
    [],
  );
  const handleOnGo2VersionSearchSelected = useCallback(
    (go2Versions: any) => {
      setGo2VersionsSelected(go2Versions as AutocompleteSearchOptionItem[]);
      updateFilterParams({
        go2Versions:
          go2Versions as unknown as KeystrokeFilterParams['go2Versions'],
      });
    },
    [setGo2VersionsSelected, updateFilterParams],
  );

  // Fields --------------------------------------------------------------------
  const fieldsAutocompleteForm = useForm<
    AutocompleteFormState & { search: string }
  >({
    search: '',
    checked: {},
    optionById: {},
  });
  const totalFieldsSelected = useMemo(() => {
    return Object.values(fieldsAutocompleteForm.formState.checked).filter(
      (checked) => checked,
    ).length;
  }, [fieldsAutocompleteForm]);

  const renderSectionTitle = (title: ReactRenderElement) => (
    <Typography
      component='div'
      pt={1.5}
      fontWeight={500}
      fontSize={14}
      sx={{
        opacity: 0.5,
      }}
    >
      {title}
    </Typography>
  );

  const renderFilterBy = () => {
    return (
      <Box flex={1} mb={2}>
        {renderSectionTitle('Filter By:')}
        <Box
          gap={2}
          display='flex'
          flexWrap='wrap'
          sx={{
            pt: 1,
            zIndex: 1,
            position: 'relative',
          }}
        >
          <FormPanel label='Date'>
            <FilterColumnPanel
              name='date'
              displayValue={`${moment(filterParams.start_date).format(
                'MMM D, YYYY',
              )} - ${moment(filterParams.end_date).format('MMM D, YYYY')}`}
              textFieldSx={{ width: 240 }}
            >
              <DateRangeSelectionPopover
                isWeekStartsOnMonday
                startDate={filterParams.start_date.format('YYYY-MM-DD')}
                endDate={filterParams.end_date.format('YYYY-MM-DD')}
                onChange={({ start, end }) => {
                  updateFilterParams({
                    start_date: moment(start).startOf('day'),
                    end_date: moment(end).endOf('day'),
                  });
                }}
              />
            </FilterColumnPanel>
          </FormPanel>
          <FormPanel label='Name' sx={{ minWidth: 300, flex: 1 }}>
            <AutocompleteSearch
              key={userSearchKeyCounter}
              multiple
              withSearchIcon
              searchKey='s'
              placeholder='Search name...'
              defaultOptionsSelected={autocompleteUserSearch.usersSelected}
              onSelect={handleOnUserSearchSelected}
              optionFormatter={autocompleteUserSearch.getUserOptionFormatter}
              onSearchTextOptions={
                autocompleteUserSearch.onUserSearchTextOptions
              }
              renderOptionItem={autocompleteUserSearch.getRenderUserOptionItem}
              useInfiniteProps={{
                isFlatResult: true,
                useQuery: useUserSearch as any,
              }}
            />
          </FormPanel>
          <FormPanel label='Division' sx={{ minWidth: 300 }}>
            <AutocompleteSearch
              key={divisionKeyCounter}
              multiple
              searchKey='name'
              placeholder='Search division...'
              onSelect={handleOnDivisionSearchSelected}
              optionFormatter={getDivisionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useAdminDivisions,
                queryParams: {
                  status: 'active',
                } as UseAdminDivisionsProps,
              }}
            />
          </FormPanel>
          <FormPanel label='Host/Domain' sx={{ minWidth: 260 }}>
            <AutocompleteSearch
              key={urlHostKeyCounter}
              multiple
              submitOnEnter
              searchKey='s'
              placeholder='Search Host/Domain...'
              defaultOptionsSelected={urlHostSelected}
              onSelect={handleOnUrlHostSearchSelected}
              optionFormatter={getUrlHostOptionFormatter}
              useInfiniteProps={{
                useQuery: useSearchUrlHosts as any,
                queryParams: {
                  limit: 30,
                },
              }}
            />
          </FormPanel>
          {/* <FormPanel label='Application'>
            <AutocompleteSelectionPopover
              width={210}
              placeholder='Select Application...'
              options={[]}
              form={
                domainAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel> */}
          {/* <FormPanel label='Domain'>
            <AutocompleteSelectionPopover
              width={210}
              placeholder='Select Domain...'
              options={[]}
              form={
                domainAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel> */}
          <FormPanel label='Go2 Version' sx={{ minWidth: 250 }}>
            <AutocompleteSearch
              key={go2VersionKeyCounter}
              multiple
              searchKey='s'
              orderGroupBy='DESC'
              placeholder='Search Go2 versions...'
              defaultOptionsSelected={go2VersionsSelected}
              onSelect={handleOnGo2VersionSearchSelected}
              optionFormatter={getGo2VersionsOptionFormatter}
              useInfiniteProps={{
                useQuery: useSearchGo2Versions as any,
                queryParams: {
                  limit: 30,
                },
              }}
            />
          </FormPanel>
          <FormPanel label='Fields' sx={{ position: 'relative', flex: 1 }}>
            <span
              style={{
                top: 2,
                right: 0,
                opacity: 0.5,
                fontSize: 12,
                position: 'absolute',
              }}
            >
              (
              {!totalFieldsSelected || totalFieldsSelected === allFields.length
                ? `All selected`
                : `${totalFieldsSelected} selected`}
              )
            </span>
            <AutocompleteSelectionPopover
              width='100%'
              placeholder={`Select${
                totalFieldsSelected ? `ed (${totalFieldsSelected})` : ''
              } field${
                !totalFieldsSelected || totalFieldsSelected > 1 ? 's' : ''
              }...`}
              options={allFields.map((field) => ({
                id: field,
                value:
                  fieldLabelMap[field] ??
                  toTitleCase(stripToFormatterWord(field)),
              }))}
              form={
                fieldsAutocompleteForm as unknown as FormStateRet<AutocompleteFormState>
              }
            />
          </FormPanel>
        </Box>
      </Box>
    );
  };

  const renderTag = ({ id, tag, label, onDelete }: any) => {
    return (
      <Chip
        size='small'
        key={id}
        label={
          <span style={{ fontWeight: 500 }}>
            <span
              style={{
                opacity: 0.39,
                paddingRight: 4,
                fontWeight: 400,
              }}
            >
              {tag}:
            </span>
            {label}
          </span>
        }
        onDelete={onDelete}
      />
    );
  };

  const renderTags = () => {
    return (
      <Stack direction='row' gap={1} flexWrap='wrap' mb={1}>
        {autocompleteUserSearch.usersSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Name',
            label: opt.label,
            onDelete: () => {
              setUserSearchKeyCounter((c) => ++c);
              handleOnUserSearchSelected(
                autocompleteUserSearch.usersSelected.filter(
                  (u) => u.id !== opt.id,
                ),
              );
            },
          });
        })}
        {divisionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Division',
            label: opt.label,
            onDelete: () => {
              setDivisionKeyCounter((c) => ++c);
              handleOnDivisionSearchSelected(
                divisionsSelected.filter((u) => u.id !== opt.id),
              );
            },
          });
        })}
        {urlHostSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Host/Domain',
            label: opt.label,
            onDelete: () => {
              setUrlHostKeyCounter((c) => ++c);
              handleOnUrlHostSearchSelected(
                urlHostSelected.filter((u) => u.id !== opt.id),
              );
            },
          });
        })}
        {go2VersionsSelected.map((opt) => {
          return renderTag({
            id: opt.id,
            tag: 'Go2 Version',
            label: opt.label,
            onDelete: () => {
              setGo2VersionKeyCounter((c) => ++c);
              handleOnGo2VersionSearchSelected(
                go2VersionsSelected.filter((go2v) => go2v.id !== opt.id),
              );
            },
          });
        })}
      </Stack>
    );
  };

  useEffect(() => {
    const fields = Object.keys(fieldsAutocompleteForm.formState.checked).filter(
      (key) => fieldsAutocompleteForm.formState.checked[key],
    );
    onFieldsChange?.(fields as (keyof UserKeystroke)[]);
  }, [onFieldsChange, fieldsAutocompleteForm.formState]);

  return (
    <Box mt={-2}>
      <M3SurfaceContainer
        elevation={1}
        sx={{
          mb: 3,
          padding: '12px 30px 18px',
        }}
      >
        {/* {renderSearchBy()} */}
        {renderFilterBy()}
        {renderTags()}
        {/* <Box
          gap={2}
          display='flex'
          justifyContent='space-between'
          sx={{
            pb: 1,
            pt: 4,
          }}
        >
          <M3Button
            type='submit'
            color='primary'
            variant='contained'
            sx={{
              width: 100,
            }}
          >
            Submit
          </M3Button>
          <M3Button
            disabled
            color='secondary'
            variant='outlined'
            sx={{
              width: 110,
            }}
          >
            Save Filters
          </M3Button>
        </Box> */}
      </M3SurfaceContainer>
    </Box>
  );
};

export default FiltersBoard;
