import { UseQueryOptions } from '@tanstack/react-query';
import { defaultReactQueryParams } from '../../utils/request';
import { useIdentifier } from '../global/useIdentifier';
import { ListQuery } from '../../types/request';
import { ListResult } from '../../types/response';
import { useQueryPortalServiceApi } from '../global/usePortalServiceApi';

export type UseKeystrokesProps = ListQuery & {
  start_date: string;
  end_date: string;
  user_ids?: string;
  execs?: string;
  url?: string;
  go2_member_ids?: string;
  member_names?: string;
  go2_versions?: string;
  order_by?: string;
  divisions?: string;
};
export function useKeystrokes<T = any>(
  params: UseKeystrokesProps,
  reactQueryParams?: UseQueryOptions,
) {
  const { identifiers, queryParams } = useIdentifier<UseKeystrokesProps>({
    params,
    baseIdentifier: 'useKeystrokes',
    identifierKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'go2_versions',
      'order_by',
      'divisions',
    ],
    queryParamKeys: [
      'start_date',
      'end_date',
      'user_ids',
      'execs',
      'url',
      'go2_member_ids',
      'member_names',
      'go2_versions',
      'order_by',
      'divisions',
    ],
  });

  return useQueryPortalServiceApi<UseKeystrokesProps, ListResult<T>>(
    identifiers,
    '/go2-agent/keystroke',
    queryParams,
    {
      ...defaultReactQueryParams,
      ...reactQueryParams,
      select(data) {
        data.results = data.results.map((item: any) => ({
          ...item,
          id: item.id || `${item.go2_member_id}_${item.timestamp}`,
          exec: item.exec || item.app,
        }));

        return data;
      },
    },
  );
}
