import React, { CSSProperties, useCallback, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

import MainContainer from '../../components/MainContainer';
import PageTitle from '../../components/Title/PageTitle';
import DataTable from '../../components/DataTable/DataTable';
import FiltersBoard, { KeystrokeFilterParams } from './FiltersBoard';
import { M3Button, M3IconButton } from '../../components/M3/M3Button';
import { UserActivitySkeleton } from '../../components/User/UserSkeleton';
import DocumentTitle from '../../components/DocumentTitle';
import InfiniteScrollListener from '../../components/InfiniteScrollListener';
import WindowScrollTop from '../../components/WindowScrollTop';

import { UserKeystroke } from './types';
import { stripToFormatterWord, toTitleCase } from '../../utils/string';
import {
  useKeystrokes,
  UseKeystrokesProps,
} from '../../hooks/go2-agent/keystroke';
import { useInfinite } from '../../hooks/global/useInfinite';
import { getWeekRangeStartsInMonday } from '../../utils/date';
import { useUserProvider } from '../../providers/user/user';
import { getBasename, toURL } from '../../utils/url';
import { useAuthProvider } from '../../providers/auth/auth';
import { getConfigWithAuthorization } from '../../services/base';
import { guessTimezone } from '../../utils/timezone';
import { fetchStartDownloadToCSV } from '../../utils/download';

type KeystrokesProps = {};

const keystrokeAppFieldLabelMap: {
  [K in keyof Partial<UserKeystroke>]: string;
} = {
  id: 'ID',
  division: 'Division',
  url: 'Host',
  user_id: 'Name',
  exec: 'Application',
  task_name: 'Focus',
};

const agentFieldStyleMap: {
  [K in keyof Partial<UserKeystroke>]: CSSProperties;
} = {
  user_id: {
    width: 260,
    minWidth: 260,
  },
  division: {
    width: 300,
    minWidth: 300,
  },
  timestamp: {
    width: 260,
    minWidth: 260,
  },
  exec: {
    width: 240,
    minWidth: 240,
  },
  url: {
    width: 280,
    minWidth: 280,
  },
  keystrokes: {
    flex: 1,
    width: 0,
    minWidth: 420,
  },
  go2_version: {
    width: 180,
    minWidth: 180,
  },
  task_name: {
    width: 300,
    minWidth: 300,
  },
};

const defaultAgentFields: (keyof UserKeystroke)[] = [
  'user_id',
  'division',
  'timestamp',
  'task_name',
  'go2_version',
  'exec',
  'url',
  'keystrokes',
];

const defaultActiveField: keyof UserKeystroke = 'timestamp';

const Keystrokes = (props: KeystrokesProps) => {
  const { setUniqueIdsToFetch, getUser } = useUserProvider();
  const { getTokenSilently } = useAuthProvider();

  const [filters, setFilters] = useState<{
    active: keyof UserKeystroke;
    order: 'ASC' | 'DESC';
  }>({
    active: defaultActiveField,
    order: 'DESC',
  });
  const [isFiltersVisible, setIsFiltersVisible] = useState(true);
  const [dataTableKey, setDataTableKey] = useState(0);

  const [selectedFields, setSelectedFields] = useState<(keyof UserKeystroke)[]>(
    [],
  );

  const handleColumnItem = useCallback(
    (field: keyof UserKeystroke, item: UserKeystroke) => {
      if (field === 'timestamp') {
        return `${moment
          .utc(item[field])
          .local()
          .format('MMM D, YYYY hh:mma')}`;
      }

      if (field === 'user_id') {
        let user = getUser('user_id', item.user_id);

        return (
          <UserActivitySkeleton
            user={user}
            linkProps={{
              to: `/users/${user?.id}/keystrokes`,
              state: {
                item,
                user,
              },
            }}
          />
        );
      }

      if (field === 'exec') {
        let exec = item[field];
        return exec ? getBasename(exec) : '';
      }

      if (field === 'division') {
        let user = getUser('user_id', item.user_id);
        return user ? user.division?.name : '...';
      }

      if (field === 'url' && item[field]) {
        let url = item[field];
        try {
          url = new URL(
            (item[field].startsWith('http') ? '' : 'https://') + item[field],
          ).host;
        } catch (e) {}
        return (
          <a
            href={item[field]}
            target='_blank'
            rel='noreferrer'
            className='m3-button-link'
          >
            {url}
          </a>
        );
      }

      return item[field];
    },
    [getUser],
  );
  const handleHeaderColumnItem = useCallback(
    (field: keyof UserKeystroke) =>
      keystrokeAppFieldLabelMap[field] ??
      toTitleCase(stripToFormatterWord(field)),
    [],
  );
  const handleOnHeaderColumnClick = useCallback(
    (field: keyof UserKeystroke, order?: 'ASC' | 'DESC' | null) => {
      setFilters((filters) => {
        filters = { ...filters };
        if (filters.active === field) {
          filters.order = order ?? filters.order === 'ASC' ? 'DESC' : 'ASC';
        } else {
          filters.active = field;
          filters.order = order ?? 'DESC';
        }
        return filters;
      });
    },
    [setFilters],
  );
  const handleOnFieldsChange = useCallback(
    (newFields: (keyof UserKeystroke)[]) => {
      setSelectedFields(
        newFields.length
          ? defaultAgentFields.filter((field) => newFields.includes(field))
          : [],
      );
      // Check if there's new fields selected
      // Check if the current filters is none on the list of fields
      if (newFields.length && !newFields.includes(filters.active)) {
        handleOnHeaderColumnClick(newFields[0]);
      }

      setDataTableKey((key) => ++key);
    },
    [filters, setSelectedFields, setDataTableKey, handleOnHeaderColumnClick],
  );

  // Filters --------------------------------------------------
  const [filterParams, setFilterParams] = useState<KeystrokeFilterParams>(
    () => ({
      start_date: getWeekRangeStartsInMonday(moment()).start,
      end_date: getWeekRangeStartsInMonday(moment()).end,
      users: [],
      divisions: [],
      urlHosts: [],
      go2Versions: [],
    }),
  );
  const updateFilterParams = useCallback(
    (params: Partial<KeystrokeFilterParams>) => {
      setFilterParams((state) => ({
        ...state,
        ...params,
      }));
    },
    [setFilterParams],
  );

  // Keystrokes ----------------------------------------------------------------
  const keystrokeLimit = 100;
  const infiniteKeystrokes = useInfinite<UserKeystroke, UseKeystrokesProps>({
    skipFetchOnInit: true,
    useQuery: useKeystrokes,
    queryParams: {
      start_date: filterParams.start_date.format(),
      end_date: filterParams.end_date.format(),
      user_ids: filterParams.users.map((u) => u.id).join(),
      url: filterParams.urlHosts.map((u) => u.id).join(),
      divisions: filterParams.divisions.map((u) => u.label).join(),
      go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
      limit: keystrokeLimit,
    },
  });

  const [isDownloading, setIsDownloading] = useState(false);
  const handleOnDownloadKeystroke = useCallback(async () => {
    try {
      setIsDownloading(true);
      let url = toURL(
        `${process.env.REACT_APP_PORTAL_SERVICE_URL}/api/go2-agent/keystroke/download`,
        {
          start_date: filterParams.start_date.format(),
          end_date: filterParams.end_date.format(),
          user_ids: filterParams.users.map((u) => u.id).join(),
          url: filterParams.urlHosts.map((u) => u.id).join(),
          divisions: filterParams.divisions.map((u) => u.label).join(),
          go2_versions: filterParams.go2Versions.map((u) => u.id).join(),
        },
      );
      await fetchStartDownloadToCSV(
        url,
        {
          headers: {
            ...getConfigWithAuthorization(await getTokenSilently()).headers,
            ['x-timezone']: guessTimezone(), // eslint-disable-line
          },
        },
        {
          filename: `keystroke_${filterParams.start_date.format(
            'YYYY-MM-DD',
          )}_${filterParams.end_date.format('YYYY-MM-DD')}.csv`,
        },
      );
    } finally {
      setIsDownloading(false);
    }
  }, [filterParams, getTokenSilently, setIsDownloading]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: infiniteKeystrokes.data.map((d) => d.user_id),
    });
  }, [infiniteKeystrokes.data, setUniqueIdsToFetch]);

  useEffect(() => {
    setUniqueIdsToFetch({
      user_ids: filterParams.users.map((d) => d.id),
    });
  }, [filterParams.users, setUniqueIdsToFetch]);

  useEffect(() => {
    infiniteKeystrokes.reset({
      emptyList: true,
    });
    // eslint-disable-next-line
  }, [filterParams]);

  return (
    <>
      <DocumentTitle title='Keystrokes' />
      <MainContainer sx={{ maxWidth: undefined }}>
        <Box
          display='flex'
          justifyContent='space-between'
          alignItems='flex-start'
        >
          <PageTitle
            title='Keystrokes'
            icon={<KeyboardAltOutlinedIcon style={{ fontSize: 34 }} />}
          />
          <Stack direction='row' gap={2}>
            {/* <M3Button color='secondary'>Use Saved Filters</M3Button> */}
            <M3Button
              active={isFiltersVisible}
              onClick={() => setIsFiltersVisible(!isFiltersVisible)}
            >
              <FilterAltOutlinedIcon />
              {isFiltersVisible ? 'Hide' : 'Show'} Filters
            </M3Button>
          </Stack>
        </Box>
        <br />
        <Box display={isFiltersVisible ? undefined : 'none'}>
          <FiltersBoard
            filterParams={filterParams}
            selectedFields={selectedFields}
            allFields={defaultAgentFields}
            fieldLabelMap={keystrokeAppFieldLabelMap}
            onFieldsChange={handleOnFieldsChange}
            updateFilterParams={updateFilterParams}
          />
        </Box>
        <br />
        <Stack direction='row'>
          <Typography component='div' style={{ marginTop: -6 }}></Typography>
          <Box display='flex' justifyContent='flex-end' gap={1} flex={1}>
            <Tooltip title='Refresh'>
              <Box>
                <M3IconButton
                  onClick={() => {
                    !infiniteKeystrokes.isLoading &&
                      infiniteKeystrokes.reset({
                        emptyList: true,
                      });
                  }}
                >
                  <RefreshOutlinedIcon
                    className={infiniteKeystrokes.isLoading ? 'spinner' : ''}
                  />
                </M3IconButton>
              </Box>
            </Tooltip>
            <Tooltip title='Download'>
              <Box>
                <M3IconButton
                  disabled={isDownloading || infiniteKeystrokes.isLoading}
                  onClick={handleOnDownloadKeystroke}
                >
                  {isDownloading ? (
                    <CircularProgress size={18} />
                  ) : (
                    <DownloadOutlinedIcon />
                  )}
                </M3IconButton>
              </Box>
            </Tooltip>
          </Box>
        </Stack>
        <Typography component='div'>
          <DataTable
            key={dataTableKey}
            orderBy={filters.order}
            loadingCount={10}
            // activeField={filters.active}
            isLoading={infiniteKeystrokes.isLoading}
            fields={selectedFields.length ? selectedFields : defaultAgentFields}
            // data={activityDataTablePagination.items}
            data={infiniteKeystrokes.data}
            fieldColumnStyleMap={agentFieldStyleMap}
            handleColumnItem={handleColumnItem}
            handleHeaderColumnItem={handleHeaderColumnItem}
            // handleOnHeaderColumnClick={handleOnHeaderColumnClick}
          />
        </Typography>
      </MainContainer>
      <InfiniteScrollListener
        onReachBottom={() => infiniteKeystrokes.loadNext()}
      />
      <WindowScrollTop />
    </>
  );
};

export default Keystrokes;
