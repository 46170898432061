import React, { useState, useRef, useCallback, SyntheticEvent } from 'react';
import { Paper, Box } from '@mui/material';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import LeaderboardOutlinedIcon from '@mui/icons-material/LeaderboardOutlined';
import LineStyleOutlinedIcon from '@mui/icons-material/LineStyleOutlined';
import KeyboardAltOutlinedIcon from '@mui/icons-material/KeyboardAltOutlined';

import NavRailMenuList, { navRailWidth } from './NavRailMenuList';
import AppDrawerMenuList from './AppDrawerMenuList';
import DrawerPermanent from './DrawerPermanent';
import PerfectScrollbar, { PerfectScrollbarRef } from './PerfectScrollbar';

import { useAppProvider } from '../providers/app/app';
import { MenuItem } from '../types/types';
import { applyOpacityOnColor } from '../utils/color';
import { getSurfaceOpacityByElevation } from './M3/M3SurfaceContainer';

type Props = {};

const AppDrawer = (props: Props) => {
  const {
    drawerWidth,
    toolbarHeight,
    palettes: { main: palette },
    isDarkMode,
    isDrawerOpen,
    setIsDrawerOpen,
    withNavRail,
  } = useAppProvider();

  const perfectScrollbarRef = useRef<null | PerfectScrollbarRef>(null);
  const [, setScrollYMenuList] = useState(0);

  const menuWidth = drawerWidth - (withNavRail ? navRailWidth : 0);
  const paperSx = {
    borderRadius: 0,
    boxShadow: 'none',
  };

  const onMenuClick = useCallback(
    (evt: SyntheticEvent, menu: MenuItem) => {
      setIsDrawerOpen(false);
    },
    [setIsDrawerOpen],
  );

  const menus: MenuItem[] = [
    {
      id: 'users',
      icon: <PeopleOutlinedIcon />,
      name: 'Users',
      basePath: '/users',
      path: '/users',
      onClick: onMenuClick,
    },
    {
      id: 'activities',
      icon: <LeaderboardOutlinedIcon />,
      name: 'Applications & Websites',
      basePath: '/activities',
      path: '/activities',
      onClick: onMenuClick,
    },
    {
      id: 'productivity',
      icon: <LineStyleOutlinedIcon />,
      name: 'Productivity',
      basePath: '/productivity',
      path: '/productivity',
      onClick: onMenuClick,
    },
    {
      id: 'keystrokes',
      icon: <KeyboardAltOutlinedIcon />,
      name: 'Keystrokes',
      basePath: '/keystrokes',
      path: '/keystrokes',
      onClick: onMenuClick,
    },
  ];

  /*
  useEffect(() => {
    let value = !withNavRail;
    let timer = setTimeout(() => {
      setWithNavRail(value);
      if (value) {
        setIsDrawerOpen(true);
      }
    }, 3000);
    return () => {
      timer && clearTimeout(timer);
    };
  }, [withNavRail, setWithNavRail]);
  */

  return (
    <DrawerPermanent
      open={isDrawerOpen}
      onClose={() => setIsDrawerOpen(false)}
      variant={withNavRail ? 'permanent' : 'temporary'}
      withEdgeCover={withNavRail}
      withDrawerBackground={withNavRail}
      withNavRail={withNavRail}
    >
      {withNavRail && <NavRailMenuList />}
      <Paper
        sx={{
          ...paperSx,
          width: menuWidth,
          display: 'flex',
          flexDirection: 'column',
          background: applyOpacityOnColor(
            isDarkMode
              ? palette['md.ref.palette.primary80']
              : palette['md.ref.palette.primary40'],
            getSurfaceOpacityByElevation(2),
          ),
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 18px',
            height: toolbarHeight,
            minHeight: toolbarHeight,
          }}
        >
          <img
            src='/icon-128.png'
            width={36}
            height={36}
            alt='Go2'
            style={{
              borderRadius: 4,
              display: 'block',
            }}
          />
        </Box>
        <Box flex={1} sx={{ height: 0 }}>
          <PerfectScrollbar onSetRef={perfectScrollbarRef}>
            <AppDrawerMenuList
              menus={menus}
              onExpanded={() => setScrollYMenuList((s) => ++s)}
            />
          </PerfectScrollbar>
        </Box>
      </Paper>
    </DrawerPermanent>
  );
};

export default AppDrawer;
