import { faker } from '@faker-js/faker';

// AUDIO RECORDINGS ------------------------------------------------------------
export const generateUserAudioRecordings = (
  count: number,
): UserAudioRecording[] => {
  return Array.from({ length: count }, (_, index) => ({
    id: index + 1,
    timestamp: faker.date.recent().toISOString(),
    name: faker.string.uuid() + '.m4a',
    url: faker.internet.url() + '/audio/' + faker.string.uuid() + '.mp3',
  }));
};

export type UserAudioRecording = {
  id: number;
  timestamp: string;
  url: string; // audio record
  name: string;
};
